
.image {
    max-width: 100%;
    max-height: 45px;
    max-width: 100%;
}

.image-container, .product-info-container, .add-button-container {
    padding: 0px;
    padding-right: 6px;
}

.image-container {
    text-align: center;
    padding: 2px;
    max-width: 11.666667% !important;    
    margin-right: 5px;
}

.add-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.product-info {
    margin: 0px;
    padding: 0px;
}

.add-button {
    float: right;
    border-radius: 13px;
    margin: auto 0px;
}

.product-name, .product-price {
    color: black;
    font-size: 12px;
    line-height: 16.1px;
    text-align: left;
    text-size-adjust: 100%;
    white-space: nowrap;
    margin: 0px
}

.product-name {
    font-weight: 400;
    white-space: normal !important;
}

.product-unit-price {
    font-size: 10.5px;
    line-height: 12.075px;
    margin: 0px;
}

mark{
    background-color: #00d6126c !important;
    padding: 0px;
}

.search-more{
    padding: 10px;
    text-align: center;
    cursor: pointer;
    color: #068442;
    text-decoration: underline;
}