.main-container {
    display: flex;
    flex-direction: column;
    height: 100% !important;
}

.header {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
}

.header-container {
    display: flex;
    flex-direction: row;
    align-content: space-between;
}

.header-title {
    display: flex;
    flex-direction: column;
}

.header-title-h3 {
    margin: 0px 0px;
    line-height: 2.2rem;
}

.header-title-p {
    margin: 0px 0px 0px 0px;
    color: rgb(100, 100, 100);
    line-height: 1.9rem;
    padding: 0.313rem 0px;
}

.products-list {
    padding: 0px 0px;
}

.textarea {
    width: 100%;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.textarea:focus {
    color: #495057;
    background-color: #fff;
    border-color: #068442;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #0684433a;
    /* box-shadow: 0 0 0 0.2rem rgba(16, 209, 9, 0.25); */
}

.footer {
    padding: 10px 20px;
}

.hr {
    margin: 5px 0rem;
    height: 1px;
    border: none;
    background: #ccc;
}

.footer-b {
    /* display: grid;
    grid-template-columns: repeat(2, 50%); */
    display: flex;
}

.footer-b-col {
    flex-grow: 2;
}

.total {
    font-size: 1.1rem;
    font-weight: 600 !important;
    color: #068442;
    margin-bottom: 0px !important;
}

.footer-b-button-container {
    /* padding: 10px; */
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.footer-b-button {
    width: 90%;
}