.loader-wrapper {
  position: absolute;
  background: #0000006e;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  .loader-wrapper {
    position:fixed;
  }
}
.circle{
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #bf4311;
  border-radius: 50%;
  animation: loading 1.5s cubic-bezier(.8, .5, .2, 1.4) infinite;
  transform-origin: bottom center;
  position: relative;
}
@keyframes loading{
  0%{
    transform: translateY(0px);
    background-color: #F44336;
  }
  50%{
    transform: translateY(50px);
    background-color: #FF9800;
  }
  100%{
    transform: translateY(0px);
    background-color: #F44336;
  }
}
.circle-1{
  animation-delay: 0.1s;
}
.circle-2{
  animation-delay: 0.2s;
}
.circle-3{
  animation-delay: 0.3s;
}
.circle-4{
  animation-delay: 0.4s;
}
.circle-5{
  animation-delay: 0.5s;
}
.circle-6{
  animation-delay: 0.6s;
}
.circle-7{
  animation-delay: 0.7s;
}
.circle-8{
  animation-delay: 0.8s;
}
.Loader-title { 
    font-size: 24px;
    color: white;
    margin-top: 69px;
}