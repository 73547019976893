.content{
    counter-reset: Seccion;
    padding: 1.25rem 3.125rem;
}

.content p {
    text-align: justify;
}

.content h3{
    counter-reset: SubSeccion;
}
.content h3:before{
    counter-increment: Seccion;
    content: counter(Seccion)". ";
}
.content h4{
    counter-reset: SubSubSeccion;
}
.content h4:before{
    counter-increment: SubSeccion;
    content: counter(Seccion) "." counter(SubSeccion)". ";
}
.content h5:before{
    counter-increment: SubSubSeccion;
    content: counter(Seccion) "." counter(SubSeccion)"." counter(SubSubSeccion)". ";
}