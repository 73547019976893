.splash-screen {
    width: 100% !important;
    height: 100% !important;
    background-size: cover !important;
    background-image: linear-gradient(-120deg, rgb(255 255 255) 40%, rgb(224 224 224) 100%);    
    z-index: 5 !important;
    position: absolute !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center!important;
}

