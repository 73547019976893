/*---Store Pages---*/
/*-----------------*/

/*Menu Cart*/
.menu-cart-item{margin:20px 0px 0px 0px; min-height:70px;}
.menu-cart-item img{width:70px;	position:absolute;}
.menu-cart-item a i{padding-right:5px;}
.menu-cart-total *{line-height:24px;margin-bottom:0px;}

.menu-cart-item strong{
    position:absolute;
    padding-left:90px;
    margin-top:-5px;
    font-size:15px;
    display:block;
}
.menu-cart-item span{
    padding:20px 70px 0px 90px;
    font-size:10px;
    line-height:15px;
    display:block;
    font-style: normal;
    color:#6a6a6a;
}

.menu-cart-item em{
    position:absolute;
    right:0px;
    top:-7px;
    font-size:12px;
    text-align:right;
    font-weight:600;
    font-style: normal;
}

.menu-cart-item em del{
    display:block;
    font-size:10px;
    margin-top:-10px;
}

.menu-cart-item a{
    padding-left:90px;
    height:40px;
    line-height:30px;
    font-size:10px;
}

/*Store Slide 1*/
.store-slide-1 .store-slide-image{margin:20px auto 30px auto;}
.store-slide-1 .store-slide-button{margin:20px 30px 20px 30px;}
.store-slide-1 .store-slide-price{margin:0px 30px;}
.store-slide-1 .store-slide-price a i{padding-right:10px;}
@media(max-width:400px){.store-slide-1 .store-slide-image{max-width:55%;}}
@media(min-width:400px){.store-slide-1 .store-slide-image{max-width:70%;}}

.store-slide-1 .store-slide-rating{width:138px;	margin:0px auto 20px auto;}
.store-slide-1 .store-slide-rating i{
    color:#F6BB42;
    font-size:15px;
    margin:0px 3px 0px 3px;
}

.store-slide-1 .store-slide-price a{
    width:60%;
    float:right;
    text-align:right;
    color:#1f1f1f;
    padding-top:0px;
    font-size:14px;
}

.store-slide-1 .store-slide-price strong{
    width:40%;
    float:left;
    text-align:left;
    font-size:20px;
    font-weight:500;
}

.store-slide-1 .store-slide-price strong del{
    color:#cacaca;
    font-size:11px;
    margin-top:-10px;
    margin-bottom:-10px;
    display:block;
}

.store-slide-1 .store-slide-price em{
    text-align:right;
    display:block;
    font-size:10px;
    margin-top:-12px
}

/*Store Slide 2*/
.store-slide-2{min-height:100px; padding:20px!important;}
.store-slide-2 .store-slide-button{padding:10px 0px 0px 90px;}
.store-slide-2 .store-slide-button a i{padding-right:10px}
.store-slide-2 .store-slide-image{
    position:absolute;
    width:80px!important;
    left:10px!important;
    top:30px;
}
.store-slide-2 .store-slide-image img{width:80px;}
.store-slide-2 .store-slide-image strong{
    text-align:center;
    display:block;
    text-decoration:none;
    color:#1f1f1f;
    font-size:14px;
}

.store-slide-2 .store-slide-title strong{
    display:block;
    padding-left:90px;
    font-size:16px;
    line-height:20px;
}

.store-slide-2 .store-slide-title em{
    font-size:12px;
    padding-left:90px;
    display:block;
    margin-top:5px;
    line-height:22px;
}
.store-slide-2 .store-slide-button strong{font-size:14px;}
.store-slide-2 .store-slide-button strong del{
    position:absolute;
    margin-top:-14px;
    text-decoration: none;
    font-weight:400;
}

.store-slide-2 .store-slide-button a{
    padding-left:10px;
    height:35px;
    min-width:35px;
    font-size:12px;
    color:#8b8b8b;
    float:right;
}

/*Menu Ball*/
.store-ball{
    font-size:8px;
    position:absolute;
    width:13px;
    height:13px;
    line-height:13px;
    border-radius:13px;
    top:12px;
    right:7px;
}

/*Featured Slider*/
.store-featured-1{
    margin-top:30px;
    padding-left:20px;
    padding-right:20px;
}

.store-featured-1 a{max-width:100px; margin:0 auto;}
.store-featured-1 img{max-width:100px;}
.store-featured-1 strong{
    display:block;
    padding-top:5px;
    font-size:13px;
    font-weight:600;
    line-height:20px;
    text-align:center;
}

.store-featured-1 u{
    display:block;
    font-size:15px;
    text-decoration:none;
    font-weight:500;
    text-align:center;
}

.store-featured-1 span{
    display:block;
    font-size:10px;
    margin-top:-10px;
    text-align:center;
}

.store-featured-1 em{
    display:block;
    font-size:11px;
    line-height:20px;
    color:#6b6b6b;
    font-style: normal;
    padding:5px 0px 10px 0px;
    text-align:center;
}

.store-featured-1-buttons{width:100px!important;margin:0 auto;}
.store-featured-1-buttons a{
    width:40px;
    float:left;
    height:40px;
    text-align:center;
    margin:10px 5px 0px 5px;
    line-height:40px;
    border-radius:10px;
    border:solid 1px rgba(0,0,0,0.1);
}

.store-categories a:nth-child(3n+2){border-bottom:none;}
.store-categories a:nth-child(3n+1){border-bottom:none; border-left:none; border-right:none;}
.store-categories a:nth-child(3n+3){border-bottom:none; border-right:none; border-left:none;}

.store-categories a{
    width:33.33333%;
    float:left;
    height:100px;
    border:solid 1px rgba(0,0,0,0.1);
    color:#1f1f1f;
}

.store-categories a i{
    position:absolute;
    text-align:center;
    width:100%;
    line-height:70px;
}
.store-categories a em{
    display: block;
    text-align:center;
    line-height:130px;
    font-style: normal;
    font-weight:500;
    font-size:11px;
    text-transform:uppercase
}

/*Store Links*/
.store-links a{
    height:40px;
    line-height:40px;
    font-size:13px;
    float:left;
    width:50%;
    color:#818181;
}

.store-links a i{
    width:30px;
    text-align:center;
    color:#1f1f1f;
}

/*Store Cover*/
.cover-store a{
    height:40px;
    line-height:40px;
    padding-top:20px;
    margin-bottom:20px;
}
.cover-store a i{height:40px; width:70px; line-height:40px;}
.cover-store a:first-child{
    color:#FFFFFF;
    font-weight:600;
    float:left;
    padding-right:20px;
    width:150px;
}

.cover-store a:nth-child(2), .cover-store a:nth-child(3){float:left; text-align:center;}
.cover-store em{
    position:absolute;
    right:0px;
    color:#FFFFFF;
    bottom:0px;
    height:0px;
    line-height:0px;
    font-size:20px;
    font-weight:300;
    font-style: normal;
}

/*Store Category Slider*/
.store-category-slider{padding:0px 10px;}
.store-category-slider a{border-radius:10px;overflow:hidden;}
.store-category-slider a strong{
    position:absolute;
    color:#FFFFFF;
    z-index:10;
    top:50%;
    margin-top:-20px;
    text-align:center;
    width:100%;
    line-height:22px;
    font-weight:600;
    font-size:14px;
    text-transform: uppercase;
}


/*Store Product 1*/
.store-product-button-fixed{position:fixed; bottom:0px; z-index:2; bottom:-12px; transform:translateY(60px);}
.show-store-product-button{transform:translateY(0px);}
.store-product-slider{margin-bottom:20px;}
.store-product-slider a{padding:0px 30px 10px 30px;}
.store-product-slider .owl-dots *{width:12px; height:12px;}
.store-product .store-product-title{font-size:15px;	padding-right:100px;}
.store-product .store-product-price{position:absolute; top:4px;	right:0px;}

.store-product .store-product-price em{
    font-size:10px;
    font-style:normal;
    display:block;
    line-height:10px;
    text-align:right;
    color:#b2b2b2;
}

.store-product .store-product-price strong{
    display:block;
    text-align:right;
    font-size:28px;
}

.store-product .store-product-reviews{
    color:#ffbb58;
    font-size:12px;
    display:block;
    margin-top:10px;
}

.store-product .store-product-reviews strong{font-weight:400;}
.store-product .store-product-reviews a{color:#9f9f9f;}
.store-product .store-product-reviews i{padding:0px 15px 0px 5px;}
.store-product .store-product-discount{
    position:absolute;
    right:0px;
    margin-top:-25px;
    font-size:11px;
    line-height:20px;
    padding:0px 8px;
}
.store-product-table *{text-align:left;	padding-left:10px;}
.store-product-select-box, .store-product-select-box select{
    line-height:45px!important;
    height:45px!important;
    font-size:12px;
    color:#1f1f1f;
}

/*Store Cart*/
.store-cart-1{min-height:120px;	padding:10px;}
.store-cart-1.order {
    min-height: 80px;
    padding: 10px;
}
.store-cart-1 img{
    position:absolute; 
    object-fit: scale-down;
    max-height: 130px;
    width: 80px; 
    left:10px;
}

.store-cart-1-remove {
    position: absolute;
    font-size: 11px;
    line-height: 10px;
    width: 80px;
    text-align: center;
    top: 103px;
    background: #EA1C28;
    color: #fff !important;
    padding: 10px;
    border-radius: 5px;
    margin-top: -20px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0,0,0,.12);
}

.store-cart-1 strong{
    display:block;
    padding-left:95px;
    line-height:18px;
    font-size:15px;
    font-weight:500;
}

.store-cart-1 span{
    font-size:11px;
    padding:0px 0px 3px 95px;
    display:block;
    line-height:12px;
}

.store-cart-1 span del{padding-right:10px;}
.store-cart-1 em{
    display:block;
    font-size:16px;
    padding-left:95px;
    padding-top:5px;
    position:absolute;
    bottom:0px;
    right:15px;
    font-style: normal;
    font-weight:400;
}
.store-cart-1.order em {
    display: block;
    font-size: 16px;
    padding-left: 95px;
    padding-top: 27px;
    position: absolute;
    top: 0px;
    right: 15px;
    font-style: normal;
    font-weight: 400;
}

.store-cart-qty{position:absolute; left:105px; bottom:2px; width: 50%;}
.store-cart-qty i{width:25px;	font-size:10px;	background-color:rgba(0,0,0,0.05);}

.store-cart-qty input{
    width:30px;
}

.store-cart-qty *{
    height:25px;
    line-height:25px!important;
    color:#1f1f1f;
    text-align:center;
}

/*Store Cart 2*/
.store-cart-2{min-height:70px; margin-bottom:40px;}
.store-cart-2 img{width:70px; position:absolute;}

.store-cart-2 span{
    padding-left:85px;
    font-size:10px;
    display:block;
    color:#a0a0a0;
    line-height:14px;
}

.store-cart-2 strong{
    padding-left:85px;
    display:block;
    line-height:20px;
    font-size:15px;
    padding-right:50px;
}

.store-cart-2 em{
    position:absolute;
    top:0px;
    font-size:14px;
    font-weight:600;
    font-style: normal;
    right:0px;
    line-height:17px;
    text-align:right;
}

.store-cart-2 em del{
    display:block;
    font-size:10px;
    color:#cacaca;
    text-align:right;
    font-weight:300;
    text-decoration: none;
}

.store-cart-2 a{
    font-size:10px;
    padding-left:85px;
    padding-top:5px;
    color:red;
}

.store-cart-2 input{
    height:25px;
    width:40px;
    font-size:10px;
    border:solid 1px rgba(0,0,0,0.1);
    text-align:center;
    position:absolute;
    right:0px;
    top:40px;
}

.store-cart-total strong{float:left; font-weight:500;}
.store-cart-total span{float:right; font-weight:500;}

/*Store Checkout*/
.checkout-total strong{float:left; margin-bottom:10px; font-weight:800}
.checkout-total span{float:right; margin-bottom:10px; font-weight:400;}

/*Store Invoice*/

.invoice-box-header{margin:0px 10px 10px 10px;}
.invoice-box{
    background:#FFFFFF;
    display:block;
    margin:0px 20px 0px 20px;
    border-radius:10px;
    padding:0px 20px;
}
.invoice-box-content{display:none; padding-bottom:5px; margin-bottom:20px;}
.invoice-box-header em{
    position:absolute;
    background-color:#1f1f1f;
    padding:0px 10px 0px 15px;
    line-height:25px;
    margin:13px 0px 0px -30px;
    font-size:11px;
    font-weight:600;
    color:#FFFFFF;
    font-style: normal;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.invoice-box-header strong{
    padding-left:40px;
    color:#969696;
    font-weight:400;
    font-size:10px;
}

.invoice-box-header span{
    font-size:15px;
    display:block;
    padding-left:40px;
    color:#1f1f1f;
    margin-top:-18px;
    padding-bottom:5px;
    font-weight:700;
}

.invoice-box-header u{
    position: absolute;
    right:-10px;
    top:12px;
    font-size:16px;
    font-weight: 800;
    text-decoration: none;
    text-align:right;
    line-height:15px;
}

.invoice-box-header u del{
    font-size:10px;
    display:block;
    text-align:right;
    font-weight:200;
    text-decoration: none;
}

.invoice-box-item em{
    display:block;
    font-size:10px;
    line-height:20px;
    font-style: normal;
    color:#acacac;
}

.invoice-box-item strong{
    font-size:14px;
    font-weight:500;
    display:block;
    margin:-5px 0px 15px 0px;
}

.invoice-box-item a u{
    float:right;
    text-decoration:none;
    color:#acacac;
}
.invoice-box-item a{font-size:12px;}



.dark-skin .store-cart-1 strong,
.dark-skin .store-cart-2 strong,
.dark-skin .store-cart-1 em,
.dark-skin .store-cart-2 em,
.dark-skin .store-cart-total strong,
.dark-skin .store-cart-total span,
.dark-skin .checkout-total,
.dark-skin .menu-cart-item strong,
.dark-skin .store-slide-2 strong,
.dark-skin .store-featured-1 u,
.dark-skin .store-featured-1 strong,
.dark-skin .store-slide-price strong,
.dark-skin .store-links a,
.dark-skin .store-product-select-box select,
.dark-skin .store-slide-price a{
    color:#FFFFFF!important;
}

.dark-skin .store-links a i{
    color:rgba(255,255,255,0.2);
}

.dark-skin .store-cart-1 .store-cart-qty i{
    color:rgba(255,255,255,0.1)!important;
}

.dark-skin .store-cart-1 input,
.dark-skin .store-product-select-box select,
.dark-skin .store-cart-2 input{
    border:solid 1px rgba(255,255,255,0.1);
    background-color:transparent;
    color:#FFFFFF;
}
