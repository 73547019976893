.product-container {
    border-bottom: 1px solid #cccccc;
    display: grid;
    grid-template-columns: 20% 48% 32%;
    padding: 10px 20px 5px 20px;

}

.product-image-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* width: 2.5rem; */
    min-width: 2.5rem;  
    padding-top: 10px;
}

.product-image {
    margin: 0px !important;
    min-height: 45px;
    max-height: 80px;

}

.product-details-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0px 0px 10px;
}

.product-name {
    margin: 0px !important;
    font-weight: 600;
    text-transform: capitalize;
    
}

.product-und-price {
    margin: 0px !important;
    font-size: 11px;
}

.product-fruver-meat {
    margin-top: 10px;
}

.product-fruver-meat-info {
    margin: 0px !important;
    display: flex;    
    flex-direction: row;
}

.product-fruver-meat-info > div {
    margin-left: 15px;
}

.maturity-0 {
    color: #068442 !important;
}

.maturity-1 {
    color: #5e8406 !important;
}

.maturity-2 {
    color: #d17000 !important;
}


.product-action-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 5px 0px;
}

.product-action-button {
    color: red;
    font-size: 20px;
    border-radius: 15px;
    padding: 4px 6px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
    right: -6px;
    position: relative;
    top: -5px;
}

.product-action-button:hover {
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.3);
}

.product-price {
    color: #068442;
    font-size: 22px;
    font-weight: 600;
    margin: 0px;
    line-height: 49px !important;
    margin-right: 10px;
}

.product-more-less-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}